import { Formik, Field, FieldProps, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react'
import { Modal, Row, Col, CardBody, Label, Input, Button, Form } from 'reactstrap';
import { tog_backdrop, CIICategory, calculateAttCII, calculateReqCII, successToast, calculateCapacity, loadingToast } from './common';
import blurReport from './Media/blurReport.jpg'
import horizontalLogo from './Media/popupLogo.png'
import './App.css';
import * as Yup from 'yup';
import { globalAPI } from './global';
import blurVoyageReport from './Media/blurVoyageReport.jpg'
import { toast } from 'react-toastify';

interface CIICalculatorModalType {
    state: boolean,
    setState: any
    id: number
    calculator: string,
    calculateSeaCO2?: any,
    calculatePortCO2?: any,
    calculateTotalEUA?: any
}

const CIICalculatorModal = ({
    state,
    setState,
    id,
    calculator,
    calculateSeaCO2,
    calculatePortCO2,
    calculateTotalEUA
}: CIICalculatorModalType) => {
    const { values } = useFormikContext<any>();
    const [url, setUrl] = useState('');

    useEffect(() => {
        toast.dismiss();
    }, [])

    useEffect(() => {
        calculator === 'cii' ?
            setUrl('cii_calculator') :
            setUrl('voyage_calculator');
    }, [calculator])

    console.log(values)

    return (
        <Modal
            isOpen={state}
            toggle={() => {
                tog_backdrop(setState);
            }}
            backdrop={"static"}
            id={'modalId'}
            size='xl'
            className='mt-5'
        >
            <div className="modal-body img-bg">
                <Row className='mb-0'>
                    <Col>
                        <button
                            type="button"
                            className="btn-close float-right"
                            onClick={() => {
                                setState(false);
                            }}
                            aria-label="Close"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} className='ps-5'>
                        <Row className='mb-5'>
                            <Col>
                                <img src={horizontalLogo} alt="" height="45" />
                            </Col>
                            <Col>
                                {calculator === 'cii' ?
                                    <h3 className="modal-title w-5rem" id="staticBackdropLabel">
                                        CII Calculator Results
                                    </h3> :
                                    <h3 className="modal-title w-5rem" id="staticBackdropLabel">
                                        Voyage Calculator Results
                                    </h3>
                                }
                            </Col>
                        </Row>
                        {/* {Object.keys(values).length > 0 && values.name_of_vessel !== null && values.type_of_vessel !== null ?
                                    ((values.name_of_vessel.toString().length + values.type_of_vessel.toString().length > 20) ?
                                        <h5 className='mt-2' style={{ color: '#3e3c3d ' }}>{values.name_of_vessel} {values.type_of_vessel}'s CII rating is </h5> :
                                        (values.name_of_vessel.toString().length + values.type_of_vessel.toString().length > 15) &&
                                            (values.name_of_vessel.toString().length + values.type_of_vessel.toString().length < 20) ?
                                            <h4 className='mt-2' style={{ color: '#3e3c3d ' }}>{values.name_of_vessel} {values.type_of_vessel}'s CII rating is </h4> :
                                            <h3 className='mt-2' style={{ color: '#3e3c3d ' }}>{values.name_of_vessel} {values.type_of_vessel}'s CII rating is </h3>) :
                                    null
                                } */}
                        {calculator === 'cii' ?
                            Object.keys(values).length > 0 && values.name_of_vessel !== null && values.type_of_vessel !== null &&
                            <>
                                <h2 className='mt-2' style={{ color: '#3e3c3d ' }}>{values.name_of_vessel} {values.type_of_vessel}'s CII rating is </h2>
                                <CardBody className={`CII-rating-${CIICategory(values, (((calculateAttCII(values) * 1000000) / calculateReqCII(values))))} card-height-3-8 ele_row`}>
                                    <Label className="align-self-center CII-category" id='rating'>{
                                        CIICategory(values, ((calculateAttCII(values) * 1000000) / calculateReqCII(values)))
                                    }</Label>
                                </CardBody>
                            </>
                            :
                            Object.keys(values).length > 0 && values.name_of_vessel !== null && values.type_of_vessel !== null &&
                            <>
                                <h2 className='mt-2' style={{ color: '#3e3c3d' }}>{values.name_of_vessel} {values.type_of_vessel}'s voyage rating is </h2>
                                <CardBody className=
                                    {`CII-rating-${CIICategory(values, (((((calculateSeaCO2 + calculatePortCO2) /
                                        (calculateCapacity(values) * values.distance_between)) * 1000000) / calculateReqCII(values))))} card-height-3-8 ele_row`}>
                                    <Label className="align-self-center CII-category" id='rating'>{
                                        CIICategory(values, (((((calculateSeaCO2 + calculatePortCO2) /
                                            (calculateCapacity(values) * values.distance_between)) * 1000000) / calculateReqCII(values))))
                                    }</Label>
                                </CardBody>
                                <h4 style={{ 'color': '#3e3c3d', marginTop: '2rem' }}>
                                    Applicable EUA is {calculateTotalEUA}
                                </h4>
                            </>
                        }
                        {/* {JSON.stringify(calculateAttCII(values) * 1000000)}<br />
                        {JSON.stringify(calculateReqCII(values))}<br />
                        {JSON.stringify(((calculateAttCII(values) * 1000000) / calculateReqCII(values)).toFixed(4))}
                        {JSON.stringify(CIICategory(values, (((calculateAttCII(values) * 1000000) / calculateReqCII(values)))))} */}
                        <div className='ele_row'>
                            <img src={calculator === 'cii' ? blurReport : blurVoyageReport}
                                style={{ 'height': '150px', 'marginTop': '150px !important' }} alt='Report' className='mt-5 mb-3 mr-2' />
                            <div className='mt-5' style={{ 'color': '#596958' }}>
                                <h5>
                                    Want to view our FREE detailed report?<br />
                                    It contains :
                                </h5>
                                <ul style={{ 'fontSize': '14pt' }}>
                                    <li>Key Insights</li>
                                    {calculator === 'cii' ?
                                        <li>CII Forecasting</li> :
                                        <li>Voyage Forecasting</li>
                                    }
                                    <li>Ecosail's suggestions</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    {
                        window.screen.width <= 445 ?
                            <Col lg={5} className='form-width' style={{
                                'backgroundColor': '#3d3d3d',
                                'borderRadius': '20px',
                                'paddingBottom': '14px',
                                'marginLeft': '35px'
                            }}>
                                <Formik
                                    onSubmit={(values: any, actions: any) => {
                                        actions.setSubmitting(false);
                                        globalAPI.patch(`${url}/${id}/`, values).then(res => {
                                            if (res.status === 200) {
                                                successToast('Your data has been successfully saved! You will receive the report via email within a few minutes.');
                                                setState(false);
                                            }
                                        }).catch(err => {
                                            console.log(err);
                                        });
                                    }}
                                    initialValues={
                                        {
                                            name: "",
                                            company_name: "",
                                            email: "",
                                            mobile: null,
                                            i_agree: false,
                                            designation: ""
                                        }
                                    }
                                    validationSchema={Yup.object({
                                        name: Yup.string().required('Field is required!'),
                                        company_name: Yup.string().required('Field is required!'),
                                        email: Yup.string().email('Invalid e-mail').required('Field is required!'),
                                        mobile: Yup.string().matches(/^\d{10}$/, 'Please enter 10 digits').required('Field is required!'),
                                    })}
                                >
                                    {props => (
                                        <Form onSubmit={props.handleSubmit}>
                                            <Row className=''>
                                                <Col className='pos-center'>
                                                    {calculator === 'cii' ?
                                                        <h4 className='font-white pos-center p-3 ms-2'>
                                                            Get our FREE CII Report for your vessel directly to your phone/email
                                                        </h4> :
                                                        <h4 className='font-white pos-center p-3 ms-2'>
                                                            Get our FREE Voyage Report for your vessel directly to your phone/email
                                                        </h4>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col className='pos-center'>
                                                    <div className='form-floating'>
                                                        <Field
                                                            type="text"
                                                            name='name'
                                                            id='name'
                                                            placeholder='name'
                                                            className='form-control small-devices-max-width h-3'
                                                        />
                                                        <Label className='d-flex align-items-center asteric'>Full name</Label>
                                                    </div>
                                                </Col><br />
                                                {props.errors && props.errors.name &&
                                                    <Label className='error pos-center'>{props.errors.name}</Label>}
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col className='pos-center'>
                                                    <div className='form-floating'>
                                                        <Field
                                                            type="text"
                                                            name='designation'
                                                            id='designation'
                                                            placeholder='designation'
                                                            className='form-control small-devices-max-width h-3'
                                                        />
                                                        <Label className='d-flex align-items-center asteric'>Designation</Label>
                                                    </div>
                                                </Col><br />
                                                {props.errors && props.errors.designation &&
                                                    <Label className='error pos-center'>{props.errors.designation}</Label>}
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col className='pos-center'>
                                                    <div className='form-floating'>
                                                        <Field
                                                            type="text"
                                                            name='company_name'
                                                            id='company_name'
                                                            placeholder='company'
                                                            className='form-control small-devices-max-width h-3'
                                                        />
                                                        <Label className='d-flex align-items-center asteric'>Company name</Label>
                                                    </div>
                                                </Col><br />
                                                {props.errors && props.errors.company_name &&
                                                    <Label className='error pos-center'>{props.errors.company_name}</Label>}
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col className='pos-center'>
                                                    <div className='form-floating'>
                                                        <Field
                                                            type="text"
                                                            name='email'
                                                            id='email'
                                                            placeholder='email'
                                                            className='form-control small-devices-max-width h-3'
                                                        />
                                                        <Label className='d-flex align-items-center asteric'>E-mail</Label>
                                                    </div>
                                                </Col><br />
                                                {props.errors && props.errors.email &&
                                                    <Label className='error pos-center'>{props.errors.email}</Label>}
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col className='pos-center'>
                                                    <div className='form-floating'>
                                                        <Field
                                                            type="text"
                                                            name='mobile'
                                                            id='mobile'
                                                            placeholder='mobile'
                                                            className='form-control small-devices-max-width h-3'
                                                        />
                                                        <Label className='d-flex align-items-center asteric'>Mobile</Label>
                                                    </div>
                                                </Col><br />
                                                {props.errors && props.errors.mobile &&
                                                    <Label className='error pos-center'>{props.errors.mobile}</Label>}
                                            </Row>
                                            <Row className='mb-4'>
                                                <Col>
                                                    <div className='ele_row'>
                                                        <Field name='i_agree'>
                                                            {({ field, form, }: FieldProps) => (
                                                                <Input
                                                                    type="checkbox"
                                                                    name='i_agree'
                                                                    id='i_agree'
                                                                    onChange={(e: any) => props.handleChange(e)}
                                                                    className='ml-15'
                                                                />
                                                            )}
                                                        </Field>
                                                        <Label className='mb-0 ml-5 font-white'>I would like to be contacted through SMS or WhatsApp from Ecosail about my ship's CII</Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='mb-2'>
                                                    <Button type='submit' style={{ 'backgroundColor': '#20a8a4' }} className='btn logo-blue btn-small-devices-max-width pos-center font-white  small-devices-max-width h-3'>Get my CII Report</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </Col> :
                            <Col lg={5} style={{
                                'backgroundColor': '#3d3d3d',
                                'borderRadius': '20px',
                                'paddingBottom': '14px',
                                'marginLeft': '35px'
                            }}>
                                <Formik
                                    onSubmit={(values: any, actions: any) => {
                                        actions.setSubmitting(false);
                                        loadingToast('Please wait as we prepare to send you this report via email')
                                        globalAPI.patch(`${url}/${id}/`, values).then(res => {
                                            if (res.status === 200) {
                                                successToast('You will receive the report via email within a few minutes.');
                                                setState(false);
                                            }
                                        }).catch(err => {
                                            console.log(err);
                                        });
                                    }}
                                    initialValues={
                                        {
                                            name: "",
                                            company_name: "",
                                            email: "",
                                            mobile: null,
                                            i_agree: false,
                                            designation: ""
                                        }
                                    }
                                    validationSchema={Yup.object({
                                        name: Yup.string().required('Field is required!'),
                                        company_name: Yup.string().required('Field is required!'),
                                        email: Yup.string().email('Invalid e-mail').required('Field is required!'),
                                        mobile: Yup.string().matches(/^\d{10}$/, 'Please enter 10 digits').required('Field is required!'),
                                    })}
                                >
                                    {props => (
                                        <Form onSubmit={props.handleSubmit}>
                                            <Row className=''>
                                                <Col className='pos-center'>
                                                    <h4 className='font-white pos-center p-3 ms-2'>
                                                        Get our FREE CII Report for your vessel directly to your phone/email
                                                    </h4>
                                                </Col>
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col className='pos-center'>
                                                    <div className='form-floating'>
                                                        <Field
                                                            type="text"
                                                            name='name'
                                                            id='name'
                                                            placeholder='name'
                                                            className='form-control w-5 h-3'
                                                        />
                                                        <Label className='d-flex align-items-center asteric'>Full name</Label>
                                                    </div>
                                                </Col><br />
                                                {props.errors && props.errors.name &&
                                                    <Label className='error pos-center'>{props.errors.name}</Label>}
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col className='pos-center'>
                                                    <div className='form-floating'>
                                                        <Field
                                                            type="text"
                                                            name='designation'
                                                            id='designation'
                                                            placeholder='designation'
                                                            className='form-control w-5 h-3'
                                                        />
                                                        <Label className='d-flex align-items-center asteric'>Designation</Label>
                                                    </div>
                                                </Col><br />
                                                {props.errors && props.errors.designation &&
                                                    <Label className='error pos-center'>{props.errors.designation}</Label>}
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col className='pos-center'>
                                                    <div className='form-floating'>
                                                        <Field
                                                            type="text"
                                                            name='company_name'
                                                            id='company_name'
                                                            placeholder='company'
                                                            className='form-control w-5 h-3'
                                                        />
                                                        <Label className='d-flex align-items-center asteric'>Company name</Label>
                                                    </div>
                                                </Col><br />
                                                {props.errors && props.errors.company_name &&
                                                    <Label className='error pos-center'>{props.errors.company_name}</Label>}
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col className='pos-center'>
                                                    <div className='form-floating'>
                                                        <Field
                                                            type="text"
                                                            name='email'
                                                            id='email'
                                                            placeholder='email'
                                                            className='form-control w-5 h-3'
                                                        />
                                                        <Label className='d-flex align-items-center asteric'>E-mail</Label>
                                                    </div>
                                                </Col><br />
                                                {props.errors && props.errors.email &&
                                                    <Label className='error pos-center'>{props.errors.email}</Label>}
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col className='pos-center'>
                                                    <div className='form-floating'>
                                                        <Field
                                                            type="text"
                                                            name='mobile'
                                                            id='mobile'
                                                            placeholder='mobile'
                                                            className='form-control w-5 h-3'
                                                        />
                                                        <Label className='d-flex align-items-center asteric'>Mobile</Label>
                                                    </div>
                                                </Col><br />
                                                {props.errors && props.errors.mobile &&
                                                    <Label className='error pos-center'>{props.errors.mobile}</Label>}
                                            </Row>
                                            <Row className='mb-4'>
                                                <Col>
                                                    <div className='ele_row'>
                                                        <Field name='i_agree'>
                                                            {({ field, form, }: FieldProps) => (
                                                                <Input
                                                                    type="checkbox"
                                                                    name='i_agree'
                                                                    id='i_agree'
                                                                    onChange={(e: any) => props.handleChange(e)}
                                                                    className='ml-15'
                                                                />
                                                            )}
                                                        </Field>
                                                        <Label className='mb-0 ml-5 font-white'>I would like to be contacted through SMS or WhatsApp from Ecosail about my ship's CII</Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='mb-2'>
                                                    <Button type='submit' style={{ 'backgroundColor': '#20a8a4' }} className='btn logo-blue btn-w-5 pos-center font-white  w-5 h-3'>Get my CII Report</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </Col>
                    }
                </Row>
            </div>
        </Modal>
    )
}

export default CIICalculatorModal