import axios from "axios";

const BaseURL: string = "https://calculator.ecosail.app/v1/api/";

export const globalAPI = axios.create({
  baseURL: `${BaseURL}`,
  headers: {
    "Content-type": "application/json",
  },
  //withCredentials: true
});
