import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CIISimulator from './CIISimulator';
import VoyageCalculator from './VoyageCalculator';

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/cii_calculator" element={<CIICalculator />} /> */}
        <Route path="/voyage_calculator" element={<CIISimulator active={true} />} />
        <Route path="/" element={<CIISimulator />} />
      </Routes>
    </Router>
  );
}

export default App;
