import { Field, FieldProps, Formik } from 'formik';
import React, { useState } from 'react'
import { Button, CardBody, Col, Form, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { CO2Emission, VesselTypes, Year, errorToast, loadFuelTypes, loadingToast } from './common';
import AsyncSelect from 'react-select/async';
import * as Yup from 'yup';
import CIICalculatorModal from './CIICalculatorModal';
import { globalAPI } from './global';

const CIICalculator = () => {
    const [modalState, setModalState] = useState(false);
    const [recordId, setRecordId] = useState(0);

    const CIICalculatorFormik = {
        initialValues: {
            name_of_vessel: '',
            imo_number: 0,
            type_of_vessel: '',
            dwt_of_vessel: 0,
            gross_tonnage: 0,
            distance_travelled: 0,
            year: null,
            type_of_fuel1: null,
            fuel_type1_value: 0,
            type_of_fuel2: null,
            fuel_type2_value: 0,
            type_of_fuel3: null,
            fuel_type3_value: 0,
            co2_emission: 0,
            name: "",
            company_name: "",
            email: "",
            mobile: null,
            i_agree: false,
            designation: ""
        },
        validationSchema: Yup.object({
            name_of_vessel: Yup.string().required('This field is required!'),
            imo_number: Yup.string().matches(/^\d{7}$/, 'Please enter 7 digits').required('This field is required!'),
            type_of_vessel: Yup.string().required('This field is required!'),
            dwt_of_vessel: Yup.string().max(6, 'Please enter number upto 6 digitis only').required('This field is required!'),
            gross_tonnage: Yup.string().max(6, 'Please enter number upto 6 digitis only').required('This field is required!'),
            distance_travelled: Yup.string().max(6, 'Please enter number upto 6 digitis only').required('This field is required!'),
            year: Yup.string().required('This field is required!'),
            type_of_fuel1: Yup.string().required('This field is required!'),
            fuel_type1_value: Yup.number().required('This field is required!'),
        })
    }
    return (
        <>
            <CardBody>
                <h5 className='mt-1 mb-4'>CII Calculator</h5>
                <Formik
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        loadingToast('Please wait! we are calculating...')
                        values.co2_emission = CO2Emission(values);
                        values.imo_number = `IMO${values.imo_number}`
                        globalAPI.post(`cii_calculator/`, values).then(res => {
                            if (res.status === 201) {
                                setRecordId(res.data.id);
                                setModalState(true);
                            }
                        }).catch(err => {
                            errorToast('Error! ' + err);
                        });
                    }}
                    initialValues={CIICalculatorFormik.initialValues}
                    validationSchema={CIICalculatorFormik.validationSchema}
                >
                    {(props: any) => (
                        <Form onSubmit={props.handleSubmit} noValidate autoComplete='off'>
                            <Row className={window.screen.width > 575 ? 'mb-3' : ''}>
                                <Col sm={4}>
                                    <Label className='mb-0 asteric'>Name of vessel</Label>
                                    <Field
                                        type="text"
                                        id='name_of_vessel'
                                        name='name_of_vessel'
                                        className='form-control mb-2'
                                    />
                                    {props.errors && props.errors.name_of_vessel &&
                                        <Label className='error'>{props.errors.name_of_vessel}</Label>
                                    }
                                </Col>
                                <Col sm={4}>
                                    <Label className='mb-0'>IMO number</Label>
                                    <Field
                                        type="text"
                                        id='imo_number'
                                        name='imo_number'
                                        className='form-control mb-2'
                                    />
                                    {props.errors && props.errors.imo_number &&
                                        <Label className='error'>{props.errors.imo_number}</Label>
                                    }
                                </Col>
                                <Col sm={4}>
                                    <Label className='mb-0 asteric'>Type of vessel</Label>
                                    <Field name='type_of_vessel'>
                                        {({ field, form, }: FieldProps) => (
                                            <Select
                                                options={VesselTypes}
                                                getOptionLabel={(option: any) => option.label}
                                                getOptionValue={(option: any) => option.label}
                                                onBlur={() => form.setFieldTouched(field.name, true)}
                                                onChange={(e: any) => {
                                                    form.setFieldValue(field.name, e === null ? '' : e.label);
                                                }}
                                                isClearable={true}
                                                className='mb-2'
                                            />
                                        )}
                                    </Field>
                                    {props.errors && props.errors.type_of_vessel &&
                                        <Label className='error'>{props.errors.type_of_vessel}</Label>
                                    }
                                </Col>
                            </Row>
                            <Row className={window.screen.width > 575 ? 'mb-3' : ''}>
                                <Col sm={4}>
                                    <Label className='mb-0 asteric'>DWT of vessel</Label>
                                    <div className='input-group mb-2'>
                                        <Field
                                            type="text"
                                            id='dwt_of_vessel'
                                            name='dwt_of_vessel'
                                            className='form-control'
                                        />
                                        <div className='input-group-text'>mt</div>
                                    </div>
                                    {props.errors && props.errors.dwt_of_vessel &&
                                        <Label className='error'>{props.errors.dwt_of_vessel}</Label>
                                    }
                                </Col>
                                <Col sm={4}>
                                    <Label className='mb-0 asteric'>Gross tonnage</Label>
                                    <div className='input-group mb-2'>
                                        <Field
                                            type="text"
                                            id='gross_tonnage'
                                            name='gross_tonnage'
                                            className='form-control'
                                        />
                                        <div className='input-group-text'>mt</div>
                                    </div>
                                    {props.errors && props.errors.gross_tonnage &&
                                        <Label className='error'>{props.errors.gross_tonnage}</Label>
                                    }
                                </Col>
                                <Col sm={4}>
                                    <Label className='mb-0 asteric'>Distance travelled during the period</Label>
                                    <div className='input-group mb-2'>
                                        <Field
                                            type="text"
                                            id='distance_travelled'
                                            name='distance_travelled'
                                            className='form-control'
                                        />
                                        <div className='input-group-text'>nm</div>
                                    </div>
                                    {props.errors && props.errors.distance_travelled &&
                                        <Label className='error'>{props.errors.distance_travelled}</Label>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Label className='mb-0 asteric'>Year</Label>
                                    <Field name='year'>
                                        {({ field, form, }: FieldProps) => (
                                            <Select
                                                options={Year}
                                                getOptionLabel={(option: any) => option.label}
                                                getOptionValue={(option: any) => option.label}
                                                onChange={(e: any) => {
                                                    form.setFieldValue(field.name, e.label);
                                                }}
                                                className='mb-2'
                                            />
                                        )}
                                    </Field>
                                    {props.errors && props.errors.year &&
                                        <Label className='error'>{props.errors.year}</Label>
                                    }
                                </Col>
                            </Row>
                            <Row className='mb-0'>
                                <h6 className='mt-3 mb-3'>Type of fuel and it's consumption</h6>
                                {window.screen.width > 575 &&
                                    <>
                                        <Col sm={4}>
                                            <Label className='asteric'>Type</Label>
                                        </Col>
                                        <Col sm={4}>
                                            <Label className='asteric'>Quantity</Label>
                                        </Col>
                                    </>
                                }
                            </Row>
                            <Row className='mb-3'>
                                <Col sm={4}>
                                    <Field
                                        name="type_of_fuel1">
                                        {({ field, form }: FieldProps) => (
                                            <AsyncSelect
                                                name={field.name}
                                                cacheOptions
                                                defaultOptions={true}
                                                loadOptions={loadFuelTypes}
                                                getOptionLabel={(e: any) => e?.fuel_type_name}
                                                getOptionValue={(e: any) => e?.id}
                                                onChange={(e: any) => {
                                                    form.setFieldValue(field.name, e === null ? '' : e.id);
                                                }}
                                                isClearable={true}
                                                menuPortalTarget={document.body}
                                                className='mb-2'
                                                placeholder={window.screen.width <= 575 ? "Type" : null}
                                            />
                                        )}
                                    </Field>
                                    {props.errors && props.errors.type_of_fuel1 &&
                                        <Label className='error'>{props.errors.type_of_fuel1}</Label>
                                    }
                                </Col>
                                <Col sm={4}>
                                    <div className='input-group mb-2'>
                                        <Field
                                            type="text"
                                            id='fuel_type1_value'
                                            name='fuel_type1_value'
                                            className='form-control'
                                        />
                                        <div className='input-group-text'>mt</div>
                                    </div>
                                    {props.errors && props.errors.fuel_type1_value &&
                                        <Label className='error'>{props.errors.fuel_type1_value}</Label>
                                    }
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col sm={4}>
                                    <Field
                                        name="type_of_fuel2">
                                        {({ field, form }: FieldProps) => (
                                            <AsyncSelect
                                                name={field.name}
                                                cacheOptions
                                                defaultOptions={true}
                                                loadOptions={loadFuelTypes}
                                                getOptionLabel={(e: any) => e?.fuel_type_name}
                                                getOptionValue={(e: any) => e?.id}
                                                onChange={(e: any) => {
                                                    form.setFieldValue(field.name, e === null ? '' : e.id);
                                                }}
                                                isClearable={true}
                                                menuPortalTarget={document.body}
                                                className='mb-2'
                                                placeholder={window.screen.width <= 575 ? "Type" : null}
                                            />
                                        )}
                                    </Field>
                                </Col>
                                <Col sm={4}>
                                    <div className='input-group mb-2'>
                                        <Field
                                            type="text"
                                            id='fuel_type2_value'
                                            name='fuel_type2_value'
                                            className='form-control'
                                        />
                                        <div className='input-group-text'>mt</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col sm={4}>
                                    <Field
                                        name="type_of_fuel3">
                                        {({ field, form }: FieldProps) => (
                                            <AsyncSelect
                                                name={field.name}
                                                cacheOptions
                                                defaultOptions={true}
                                                loadOptions={loadFuelTypes}
                                                getOptionLabel={(e: any) => e?.fuel_type_name}
                                                getOptionValue={(e: any) => e?.id}
                                                onChange={(e: any) => {
                                                    form.setFieldValue(field.name, e === null ? '' : e.id);
                                                }}
                                                isClearable={true}
                                                menuPortalTarget={document.body}
                                                className='mb-2'
                                                placeholder={window.screen.width <= 575 ? "Type" : null}
                                            />
                                        )}
                                    </Field>
                                </Col>
                                <Col sm={4}>
                                    <div className='input-group mb-2'>
                                        <Field
                                            type="text"
                                            id='fuel_type3_value'
                                            name='fuel_type3_value'
                                            className='form-control'
                                        />
                                        <div className='input-group-text'>mt</div>
                                    </div>
                                </Col>
                            </Row>
                            {/* <Row className='mt-5'>
                                    <Col sm={2}>
                                        <h5>Attained CII: </h5>
                                    </Col>
                                    <Col sm={3}>
                                        <h5>{(Number.isNaN(calculateAttCII(props.values) * 1000000) === true ? 0.0 : (calculateAttCII(props.values) * 1000000)).toFixed(2)}</h5>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col sm={2}>
                                        <h5>Reference CII: </h5>
                                    </Col>
                                    <Col sm={3}>
                                        <h5>{calculateRefCII
                                            (props.values.type_of_vessel, props.values.dwt_of_vessel, props.values.gross_tonnage).toFixed(2)}</h5>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col sm={2}>
                                        <h5>Required CII: </h5>
                                    </Col>
                                    <Col sm={3}>
                                        <h5>{calculateReqCII(props.values).toFixed(2)}</h5>
                                    </Col>
                                </Row> */}
                            <Row>
                                <Col className='pos-center'>
                                    <div className='ele_row'>
                                        <Button className='btn btn-primary mt-5' type='submit'>Calculate</Button>
                                        <Button className='btn btn-primary mt-5 ms-5' type='reset' onClick={() => {
                                            props?.resetForm({ values: CIICalculatorFormik.initialValues });
                                        }}>Reset</Button>
                                    </div>
                                </Col>
                            </Row>
                            {modalState === true &&
                                <CIICalculatorModal state={modalState} setState={setModalState} id={recordId} calculator='cii' />
                            }
                            {/* {JSON.stringify(props.values)} */}
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </>
    )
}

export default CIICalculator