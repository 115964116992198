import React, { useState } from 'react'
import { Button, Card, CardHeader, Col, Container, Nav, NavItem, Row, TabContent, TabPane, NavLink } from 'reactstrap'
import CIICalculator from './CIICalculator'
import VoyageCalculator from './VoyageCalculator'
import logo from './Media/popupLogo.png';
import { Link } from "react-router-dom";
import './App.css';
import classnames from 'classnames';

const CIISimulator = (active: any) => {
    const [activeSettingsTab, setActiveSettingsTab] = useState(active?.active === true ? '2' : '1');

    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            setActiveSettingsTab(tab);
        }
    }

    return (
        <Container fluid>
            {window.screen.width <= 445 ?
                <Row className='mt-4'>
                    <div className='ele_row'>
                        <Link to="https://ecosailinfotech.com/solutions/eco-compliance-module">
                            <Button className='btn btn-primary ml-2 align-middle'>Back</Button>
                        </Link>
                        <Link to="https://ecosailinfotech.com/">
                            <img src={logo} alt="Ecosail" height="45" className='align-center' />
                        </Link>
                        <Link to="https://ecosailinfotech.com/contact/">
                            <Button className='btn btn-primary float-right mr-2'>Contact</Button>
                        </Link>
                    </div>
                </Row> :
                <Row className='mt-4'>
                    <Col>
                        <Link to="https://ecosailinfotech.com/solutions/eco-compliance-module">
                            <Button className='btn btn-primary ml-2 align-middle'>Back</Button>
                        </Link>
                    </Col>
                    <Col>
                        <Link to="https://ecosailinfotech.com/">
                            <img src={logo} alt="Ecosail" height="45" className='align-center' />
                        </Link>
                    </Col>
                    <Col>
                        <Link to="https://ecosailinfotech.com/contact/">
                            <Button className='btn btn-primary float-right mr-2'>Contact Us</Button>
                        </Link>
                    </Col>
                </Row>
            }
            <Row className='mb-3'>
                <Container fluid>
                    <Card className='halfOpacity'>
                        <CardHeader className='pb-0'>
                            <Nav tabs className='no-border'>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={`labelGrey ${classnames({
                                            active: activeSettingsTab === "1",
                                        })}`}
                                        onClick={() => {
                                            toggle("1");
                                        }}
                                    >
                                        <h5>CII Calculator</h5>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={`labelGrey ${classnames({
                                            active: activeSettingsTab === "2",
                                        },)}`}
                                        onClick={() => {
                                            toggle("2");
                                        }}
                                    >
                                        <h5>Voyage Calculator</h5>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <TabContent activeTab={activeSettingsTab} className="text-muted">
                            <TabPane tabId="1" className='margin-3'>
                                <CIICalculator />
                            </TabPane>
                            <TabPane tabId="2" className='margin-3'>
                                <VoyageCalculator />
                            </TabPane>
                        </TabContent>
                    </Card>
                </Container>
            </Row>
        </Container >
    )
}

export default CIISimulator