export const queryKeys: any = {
  FuelTypesMaster: {
    title: "Fuel types from master",
    key: "fuel-types-master",
    url: () => `fuel_type_master/`,
  },
  VesselTypesMaster: {
    title: "Vessel types from master",
    key: "vessel-types-master",
    url: () => `vessel_type_master/`,
  },
};
