import { Formik, Field, FieldProps } from 'formik';
import React, { useState } from 'react';
import { CardBody, Row, Col, Label, Input, Form, Button } from 'reactstrap'
import { CIICategory, VesselTypes, Year, calculateCapacity, calculateReqCII, errorToast, euaMultiplyingFactor, fuelCF, loadFuelTypes, loadingToast } from './common';
import Select from 'react-select';
import * as Yup from 'yup';
import AsyncSelect from 'react-select/async';
import { globalAPI } from './global';
import CIICalculatorModal from './CIICalculatorModal';

const VoyageCalculator = () => {
    const [modalState, setModalState] = useState(false);
    const [recordId, setRecordId] = useState(0);

    const VoyageCalculatiorFormik = {
        initailValues: {
            name_of_vessel: null,
            imo_number: null,
            type_of_vessel: null,
            dwt_of_vessel: 0,
            gross_tonnage: null,
            year: null,
            departure_port: null,
            depPortLocation: 'eu',
            arrival_port: null,
            arrPortLocation: 'eu',
            distance_between: 0,
            speed: null,
            days: 0,
            steaming_days_eca: 0,
            steaming_days_non_eca: 0,
            seaPassage: {
                fuel_type1: '',
                fuel_type1_eca_value: 0,
                fuel_type1_non_eca_value: 0,
                fuel_type2: '',
                fuel_type2_eca_value: 0,
                fuel_type2_non_eca_value: 0,
                fuel_type3: '',
                fuel_type3_eca_value: 0,
                fuel_type3_non_eca_value: 0,
            },
            totalseaPassage: {
                fuel_type1: '',
                fuel_type1_eca_value: 0,
                fuel_type1_non_eca_value: 0,
                fuel_type2: '',
                fuel_type2_eca_value: 0,
                fuel_type2_non_eca_value: 0,
                fuel_type3: '',
                fuel_type3_eca_value: 0,
                fuel_type3_non_eca_value: 0,
            },
            days_at_port: null,
            inPortLocation: 'eu',
            iceClass: 'not_applicable',
            in_port_name: '',
            portStay: {
                fuel_type1: '',
                fuel_type1_value: null,
                fuel_type2: '',
                fuel_type2_value: 0,
                fuel_type3: '',
                fuel_type3_value: 0,
            },
            totalportStay: {
                fuel_type1: '',
                fuel_type1_value: null,
                fuel_type2: '',
                fuel_type2_value: 0,
                fuel_type3: '',
                fuel_type3_value: 0,
            },
            co2_emission_at_sea: 0,
            co2_emission_in_port: 0,
            required_cii: 0,
            attained_cii: 0,
            cii_ratio: 0,
            grade: '',
            eua: 0,
            voyage_co2_emission: 0,
            name: "",
            company_name: "",
            email: "",
            mobile: null,
            i_agree: false,
            designation: ""
        },
        validationSchema: Yup.object({
            name_of_vessel: Yup.string().required('Field is required!'),
            imo_number: Yup.string().matches(/^\d{7}$/, 'Please enter 7 digits').required('This field is required!'),
            type_of_vessel: Yup.string().required('This field is required!'),
            dwt_of_vessel: Yup.string().max(6, 'Please enter number upto 6 digitis only').required('This field is required!'),
            gross_tonnage: Yup.string().max(6, 'Please enter number upto 6 digitis only').required('This field is required!'),
            year: Yup.string().required('Field is required!'),
            departure_port: Yup.string().required('Field is required!'),
            arrival_port: Yup.string().required('Field is required!'),
            distance_between: Yup.string().max(6, 'Please enter number upto 6 digitis only').required('This field is required!'),
            speed: Yup.number().required('Field is required!'),
            steaming_days_eca: Yup.number().required('Field is required!'),
            seaPassage: Yup.object({
                fuel_type1: Yup.number().required('Field is required!'),
                fuel_type1_eca_value: Yup.number().required('Field is required!'),
            }),
            days_at_port: Yup.number().required('Field is required!'),
            in_port_name: Yup.string().required('Field is required!'),
            portStay: Yup.object({
                fuel_type1: Yup.number().required('Field is required!'),
                fuel_type1_value: Yup.number().required('Field is required!'),
            }),
        })
    }

    const calculateSeaECACO2 = (values: any) => {
        let cf1 = fuelCF(values.seaPassage.fuel_type1) ?? 0;
        let cf2 = fuelCF(values.seaPassage.fuel_type2) ?? 0;
        let cf3 = fuelCF(values.seaPassage.fuel_type3) ?? 0;
        return ((values.seaPassage.fuel_type1_eca_value * cf1 * values.steaming_days_eca) +
            (values.seaPassage.fuel_type2_eca_value * cf2 * values.steaming_days_eca) +
            (values.seaPassage.fuel_type3_eca_value * cf3 * values.steaming_days_eca));
    }

    const calculateSeaNonECACO2 = (values: any) => {
        let cf1 = fuelCF(values.seaPassage.fuel_type1) ?? 0;
        let cf2 = fuelCF(values.seaPassage.fuel_type2) ?? 0;
        let cf3 = fuelCF(values.seaPassage.fuel_type3) ?? 0;
        return ((values.seaPassage.fuel_type1_non_eca_value * cf1 * values.steaming_days_non_eca) +
            (values.seaPassage.fuel_type2_non_eca_value * cf2 * values.steaming_days_non_eca) +
            (values.seaPassage.fuel_type3_non_eca_value * cf3 * values.steaming_days_non_eca));
    }

    const calculateSeaCO2 = (values: any) => {
        return (calculateSeaECACO2(values) + calculateSeaNonECACO2(values));
    }

    const calculatePortCO2 = (values: any) => {
        let cf1 = fuelCF(values.portStay.fuel_type1) ?? 0;
        let cf2 = fuelCF(values.portStay.fuel_type2) ?? 0;
        let cf3 = fuelCF(values.portStay.fuel_type3) ?? 0;
        return ((values.portStay.fuel_type1_value * cf1 * values.days_at_port) +
            (values.portStay.fuel_type2_value * cf2 * values.days_at_port) +
            (values.portStay.fuel_type3_value * cf3 * values.days_at_port));
    }

    const calculateSeaEUA = (values: any) => {
        let SEAEUA = 0;
        if (values.depPortLocation === 'eu' && values.arrPortLocation === 'eu') {
            SEAEUA = calculateSeaCO2(values) * euaMultiplyingFactor(values.year) * 1
            return (SEAEUA);
        } else if ((values.depPortLocation === 'eu' && values.arrPortLocation !== 'eu') ||
            (values.depPortLocation !== 'eu' && values.arrPortLocation === 'eu')) {
            return calculateSeaCO2(values) * euaMultiplyingFactor(values.year) * 0.5;
        }
        else {
            return 0;
        }
    }

    const calculatePortEUA = (values: any) => {
        let PortEUA = 0;
        if (values.inPortLocation === 'eu') {
            PortEUA = calculatePortCO2(values) * euaMultiplyingFactor(values.year) * 1;
            return PortEUA;
        } else {
            return 0;
        }
    }

    const calculateTotalEUA = (values: any) => {
        if (values.iceClass === 'ia') {
            return ((calculateSeaEUA(values) + calculatePortEUA(values)) * 0.95).toFixed(0);
        } else {
            return (calculateSeaEUA(values) + calculatePortEUA(values)).toFixed(0);
        }
    }

    const calculateNumberofDays = (values: any, form: any) => {
        if (values.distance_between > 0 && values.speed > 0) {
            form.setFieldValue('days', ((values.distance_between / values.speed) / 24).toFixed(1));
        } else {
            form.setFieldValue('days', 0);
        }
    }

    return (
        <CardBody>
            <h5 className='mt-1 mb-4'>Voyage Calculator</h5>
            <Formik
                onSubmit={(values: any, actions: any) => {
                    actions.setSubmitting(false);
                    loadingToast('Please wait! we are calculating...')
                    values.co2_emission_at_sea = calculateSeaCO2(values).toFixed(4);
                    values.co2_emission_in_port = calculatePortCO2(values).toFixed(4);
                    values.required_cii = calculateReqCII(values).toFixed(4);
                    values.attained_cii = (((calculateSeaCO2(values) + calculatePortCO2(values)) /
                        (calculateCapacity(values) * values.distance_between)) * 1000000).toFixed(4);
                    values.cii_ratio = (((((calculateSeaCO2(values) + calculatePortCO2(values)) /
                        (calculateCapacity(values) * values.distance_between)) * 1000000) / calculateReqCII(values))).toFixed(4);
                    values.voyage_co2_emission = (calculateSeaCO2(values) + calculatePortCO2(values)).toFixed(4);
                    values.grade = CIICategory(values, (((((calculateSeaCO2(values) + calculatePortCO2(values)) /
                        (calculateCapacity(values) * values.distance_between)) * 1000000) / calculateReqCII(values))));
                    values.eua = calculateTotalEUA(values)
                    globalAPI.post(`voyage_calculator/`, values).then(res => {
                        if (res.status === 201) {
                            setRecordId(res.data.id);
                            setModalState(true);
                        }
                    }).catch(err => {
                        errorToast('Error! ' + err);
                    });
                }}
                initialValues={VoyageCalculatiorFormik.initailValues}
                validationSchema={VoyageCalculatiorFormik.validationSchema}
            >
                {props => (
                    <Form onSubmit={props.handleSubmit} noValidate autoComplete='off'>
                        <Row className={window.screen.width > 575 ? 'mb-3' : ''}>
                            <Col sm={4}>
                                <Label className='mb-0 asteric'>Name of vessel</Label>
                                <Field
                                    type="text"
                                    id='name_of_vessel'
                                    name='name_of_vessel'
                                    className='form-control mb-2'
                                />
                                {props.errors && props.errors.name_of_vessel &&
                                    <Label className='error'>{props.errors.name_of_vessel}</Label>
                                }
                            </Col>
                            <Col sm={4}>
                                <Label className='mb-0 asteric'>IMO number</Label>
                                <Field
                                    type="text"
                                    id='imo_number'
                                    name='imo_number'
                                    className='form-control mb-2'
                                />
                                {props.errors && props.errors.imo_number &&
                                    <Label className='error'>{props.errors.imo_number}</Label>
                                }
                            </Col>
                            <Col sm={4}>
                                <Label className='mb-0 asteric'>Type of vessel</Label>
                                <Field name='type_of_vessel'>
                                    {({ field, form, }: FieldProps) => (
                                        <Select
                                            options={VesselTypes}
                                            getOptionLabel={(option: any) => option.label}
                                            getOptionValue={(option: any) => option.label}
                                            onChange={(e: any) => {
                                                form.setFieldValue(field.name, e.label);
                                            }}
                                            defaultValue={{ label: props.values.type_of_vessel }}
                                            className='mb-2'
                                        />
                                    )}
                                </Field>
                                {props.errors && props.errors.type_of_vessel &&
                                    <Label className='error'>{props.errors.type_of_vessel}</Label>
                                }
                            </Col>
                        </Row>
                        <Row className={window.screen.width > 575 ? 'mb-3' : ''}>
                            <Col sm={4}>
                                <Label className='mb-0 asteric'>DWT of vessel</Label>
                                <div className='input-group mb-2'>
                                    <Field name='dwt_of_vessel'>
                                        {({ field, form, }: FieldProps) => (
                                            <Input
                                                type="text"
                                                id='dwt_of_vessel'
                                                name={field.name}
                                                className='form-control'
                                                onChange={(e: any) => {
                                                    props.handleChange(e);
                                                    calculateNumberofDays(props.values, form);
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <div className='input-group-text'>mt</div>
                                </div>
                                {props.errors && props.errors.dwt_of_vessel &&
                                    <Label className='error'>{props.errors.dwt_of_vessel}</Label>
                                }
                            </Col>
                            <Col sm={4}>
                                <Label className='mb-0 asteric'>Gross tonnage</Label>
                                <div className='input-group mb-2'>
                                    <Field
                                        type="text"
                                        id='gross_tonnage'
                                        name='gross_tonnage'
                                        className='form-control'
                                    />
                                    <div className='input-group-text'>mt</div>
                                </div>
                                {props.errors && props.errors.gross_tonnage &&
                                    <Label className='error'>{props.errors.gross_tonnage}</Label>
                                }
                            </Col>
                            <Col sm={4}>
                                <Label className='mb-0 asteric'>Year</Label>
                                <Field name='year'>
                                    {({ field, form, }: FieldProps) => (
                                        <Select
                                            options={Year.filter((item: any) => item.label >= 2024 && item.label <= 2026)}
                                            getOptionLabel={(option: any) => option.label}
                                            getOptionValue={(option: any) => option.label}
                                            onChange={(e: any) => {
                                                form.setFieldValue(field.name, e.label);
                                            }}
                                            className='mb-2'
                                        />
                                    )}
                                </Field>
                                {props.errors && props.errors.year &&
                                    <Label className='error'>{props.errors.year}</Label>
                                }
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Col sm={2}>
                                <Label className='mt-3'>Ice class applicability</Label>
                            </Col>
                            <Col sm={2}>
                                <div className="form-check mt-3">
                                    <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="iceClass"
                                        id="ia"
                                        value="ia"
                                        defaultChecked
                                    />
                                    <Label className="form-check-label" htmlFor="ia">
                                        IA/IA Super
                                    </Label>
                                </div>
                            </Col>
                            <Col sm={2}>
                                <div className="form-check mt-3">
                                    <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="iceClass"
                                        id="not_applicable"
                                        value="not_applicable"
                                    />
                                    <Label className="form-check-label" htmlFor="not_applicable">
                                        NA
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <h5>At Sea Operation</h5>
                        </Row>
                        <Row className='mb-3'>
                            <Col sm={4}>
                                <Label className='mb-0 asteric'>Departure port</Label>
                                <Field
                                    type="text"
                                    id='departure_port'
                                    name='departure_port'
                                    className='form-control mb-2'
                                />
                                {props.errors && props.errors.departure_port &&
                                    <Label className='error'>{props.errors.departure_port}</Label>
                                }
                            </Col>
                            <Col sm={1}>
                                <div className="form-check mt-3">
                                    <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="depPortLocation"
                                        id="eu"
                                        value="eu"
                                        defaultChecked
                                    />
                                    <Label className="form-check-label" htmlFor="eu">
                                        EU
                                    </Label>
                                </div>
                            </Col>
                            <Col sm={2}>
                                <div className="form-check mt-3">
                                    <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="depPortLocation"
                                        id="non_eu"
                                        value="non_eu"
                                    />
                                    <Label className="form-check-label" htmlFor="non_eu">
                                        NON EU
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col sm={4}>
                                <Label className='mb-0 asteric'>Arrival port</Label>
                                <Field
                                    type="text"
                                    id='arrival_port'
                                    name='arrival_port'
                                    className='form-control mb-2'
                                />
                                {props.errors && props.errors.arrival_port &&
                                    <Label className='error'>{props.errors.arrival_port}</Label>
                                }
                            </Col>
                            <Col sm={1}>
                                <div className="form-check mt-3">
                                    <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="arrPortLocation"
                                        id="eu"
                                        value="eu"
                                        defaultChecked
                                    />
                                    <Label className="form-check-label" htmlFor="eu">
                                        EU
                                    </Label>
                                </div>
                            </Col>
                            <Col sm={2}>
                                <div className="form-check mt-3">
                                    <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="arrPortLocation"
                                        id="non_eu"
                                        value="non_eu"
                                    />
                                    <Label className="form-check-label" htmlFor="non_eu">
                                        NON EU
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col sm={4}>
                                <Label className='mb-0 asteric'>Distance between the ports</Label>
                                <div className='input-group mb-2'>
                                    <Field name='distance_between'>
                                        {({ field, form, }: FieldProps) => (
                                            <Input
                                                type="text"
                                                id='distance_between'
                                                name={field.name}
                                                className='form-control'
                                                onChange={(e: any) => {
                                                    props.handleChange(e);
                                                    calculateNumberofDays(props.values, form);
                                                }}
                                                onBlur={(e: any) => {
                                                    props.handleChange(e);
                                                    calculateNumberofDays(props.values, form);
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <div className='input-group-text'>nm</div>
                                </div>
                                {props.errors && props.errors.distance_between &&
                                    <Label className='error'>{props.errors.distance_between}</Label>
                                }
                            </Col>
                            <Col sm={4}>
                                <Label className='mb-0 asteric'>Speed</Label>
                                <div className='input-group mb-2'>
                                    <Field name='speed'>
                                        {({ field, form, }: FieldProps) => (
                                            <Input
                                                type="text"
                                                id='speed'
                                                name={field.name}
                                                className='form-control'
                                                onChange={(e: any) => {
                                                    props.handleChange(e);
                                                    calculateNumberofDays(props.values, form);
                                                }}
                                                onBlur={(e: any) => {
                                                    props.handleChange(e);
                                                    calculateNumberofDays(props.values, form);
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <div className='input-group-text'>knots</div>
                                </div>
                                {props.errors && props.errors.speed &&
                                    <Label className='error'>{props.errors.speed}</Label>
                                }
                            </Col>
                            <Col sm={4}>
                                <Label className='mb-0 asteric'>Total days</Label>
                                <Field
                                    type="text"
                                    id='days'
                                    name='days'
                                    className='form-control mb-2'
                                    disabled
                                />
                                {props.errors && props.errors.days &&
                                    <Label className='error'>{props.errors.days}</Label>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <h6>Steaming days</h6>
                        </Row>
                        <Row className='mb-3'>
                            <Col sm={4}>
                                <Label className='mb-0 asteric'>In ECA</Label>
                                <Field name={`steaming_days_eca`}>
                                    {({ field, form }: FieldProps) => (
                                        <Input
                                            type="text"
                                            id={field.name}
                                            name={field.name}
                                            className='form-control mb-2'
                                            onChange={(e: any) => {
                                                props.handleChange(e);
                                                form.setFieldValue('steaming_days_non_eca', (props.values.days - props.values.steaming_days_eca).toFixed(1))
                                            }}
                                            onBlur={(e: any) => {
                                                props.handleChange(e);
                                                form.setFieldValue('steaming_days_non_eca', (props.values.days - props.values.steaming_days_eca).toFixed(1))
                                            }}
                                        />
                                    )}
                                </Field>
                                {props.errors && props.errors.steaming_days_eca &&
                                    <Label className='error'>{props.errors.steaming_days_eca}</Label>
                                }
                            </Col>
                            <Col sm={4}>
                                <Label className='mb-0'>Outside ECA</Label>
                                <Field
                                    type="text"
                                    id='steaming_days_non_eca'
                                    name='steaming_days_non_eca'
                                    className='form-control mb-2'
                                    value={(props.values.days - props.values.steaming_days_eca).toFixed(1)}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <h6>Fuel consumption per day during sea passage</h6>
                        </Row>
                        {window.screen.width > 575 &&
                            <>
                                <Row>
                                    <Col sm={{ size: 4, offset: 4 }}>
                                        <h6>In ECA</h6>
                                    </Col>
                                    <Col sm={4}>
                                        <h6>Outside ECA</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4}>
                                        <Label>Type</Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label>Quantity</Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label>Quantity</Label>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Row className='mb-3'>
                            <Col sm={4}>
                                <Field
                                    name="seaPassage.fuel_type1">
                                    {({ field, form }: FieldProps) => (
                                        <AsyncSelect
                                            name={field.name}
                                            cacheOptions
                                            defaultOptions={true}
                                            loadOptions={loadFuelTypes}
                                            getOptionLabel={(e: any) => e?.fuel_type_name}
                                            getOptionValue={(e: any) => e?.id}
                                            onChange={(e: any) => {
                                                form.setFieldValue(field.name, e === null ? '' : e.id);
                                                form.setFieldValue('totalseaPassage.fuel_type1', e === null ? '' : e.id);
                                            }}
                                            menuPortalTarget={document.body}
                                            className='mb-2'
                                            placeholder={window.screen.width <= 575 ? "Type" : null}
                                        />
                                    )}
                                </Field>
                                {props.errors && props.errors.seaPassage &&
                                    <Label className='error'>{props.errors.seaPassage.fuel_type1}</Label>
                                }
                            </Col>
                            <Col sm={4}>
                                <div className='input-group mb-2'>
                                    <Field name='seaPassage.fuel_type1_eca_value'>
                                        {({ field }: FieldProps) => (
                                            <Input
                                                type="text"
                                                id='seaPassage.fuel_type1_eca_value'
                                                className='form-control'
                                                name={field.name}
                                                onBlur={(e: any) => {
                                                    props.handleChange(e);
                                                    props.setFieldValue('totalseaPassage.fuel_type1_eca_value',
                                                        e.target.value * props?.values?.days)
                                                }}
                                                placeholder={window.screen.width <= 575 ? "Consumption in ECA" : ""}
                                            />
                                        )}
                                    </Field>
                                    <div className='input-group-text'>mt</div>
                                </div>
                                {props.errors && props.errors.seaPassage &&
                                    <Label className='error'>{props.errors.seaPassage.fuel_type1_eca_value}</Label>
                                }
                            </Col>
                            <Col sm={4}>
                                <div className='input-group mb-2'>
                                    <Field name='seaPassage.fuel_type1_non_eca_value'>
                                        {({ field }: FieldProps) => (
                                            <Input
                                                type="text"
                                                id='seaPassage.fuel_type1_non_eca_value'
                                                name={field.name}
                                                className='form-control'
                                                onBlur={(e: any) => {
                                                    props.handleChange(e);
                                                    props.setFieldValue('totalseaPassage.fuel_type1_non_eca_value',
                                                        e.target.value * props?.values?.days)
                                                }}
                                                placeholder={window.screen.width <= 575 ? "Consumption outside ECA" : ""}
                                            />
                                        )}
                                    </Field>
                                    <div className='input-group-text'>mt</div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col sm={4}>
                                <Field
                                    name="seaPassage.fuel_type2">
                                    {({ field, form }: FieldProps) => (
                                        <AsyncSelect
                                            name={field.name}
                                            cacheOptions
                                            defaultOptions={true}
                                            loadOptions={loadFuelTypes}
                                            getOptionLabel={(e: any) => e?.fuel_type_name}
                                            getOptionValue={(e: any) => e?.id}
                                            onChange={(e: any) => {
                                                form.setFieldValue(field.name, e === null ? '' : e.id);
                                                form.setFieldValue('totalseaPassage.fuel_type2', e === null ? '' : e.id);
                                            }}
                                            isClearable={true}
                                            menuPortalTarget={document.body}
                                            className='mb-2'
                                            placeholder={window.screen.width <= 575 ? "Type" : null}
                                        />
                                    )}
                                </Field>
                            </Col>
                            <Col sm={4}>
                                <div className='input-group mb-2'>
                                    <Field name='seaPassage.fuel_type2_eca_value'>
                                        {({ field, form }: FieldProps) => (
                                            <Input
                                                type="text"
                                                id='seaPassage.fuel_type2_eca_value'
                                                className='form-control'
                                                name={field.name}
                                                onBlur={(e: any) => {
                                                    props.handleChange(e);
                                                    props.setFieldValue('totalseaPassage.fuel_type2_eca_value',
                                                        e.target.value * props?.values?.days)
                                                }}
                                                placeholder={window.screen.width <= 575 ? "Consumption in ECA" : ""}
                                            />
                                        )}
                                    </Field>
                                    <div className='input-group-text'>mt</div>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className='input-group mb-2'>
                                    <Field name='seaPassage.fuel_type2_non_eca_value'>
                                        {({ field, form }: FieldProps) => (
                                            <Input
                                                type="text"
                                                id='seaPassage.fuel_type2_non_eca_value'
                                                className='form-control'
                                                name={field.name}
                                                onBlur={(e: any) => {
                                                    props.handleChange(e);
                                                    props.setFieldValue('totalseaPassage.fuel_type2_non_eca_value',
                                                        e.target.value * props?.values?.days)
                                                }}
                                                placeholder={window.screen.width <= 575 ? "Consumption outside ECA" : ""}
                                            />
                                        )}
                                    </Field>
                                    <div className='input-group-text'>mt</div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col sm={4}>
                                <Field
                                    name="seaPassage.fuel_type3">
                                    {({ field, form }: FieldProps) => (
                                        <AsyncSelect
                                            name={field.name}
                                            cacheOptions
                                            defaultOptions={true}
                                            loadOptions={loadFuelTypes}
                                            getOptionLabel={(e: any) => e?.fuel_type_name}
                                            getOptionValue={(e: any) => e?.id}
                                            onChange={(e: any) => {
                                                form.setFieldValue(field.name, e === null ? '' : e.id);
                                                form.setFieldValue('totalseaPassage.fuel_type3', e === null ? '' : e.id);
                                            }}
                                            isClearable={true}
                                            menuPortalTarget={document.body}
                                            className='mb-2'
                                            placeholder={window.screen.width <= 575 ? "Type" : null}
                                        />
                                    )}
                                </Field>
                            </Col>
                            <Col sm={4}>
                                <div className='input-group mb-2'>
                                    <Field name='seaPassage.fuel_type3_eca_value'>
                                        {({ field }: FieldProps) => (
                                            <Input
                                                type="text"
                                                id='seaPassage.fuel_type3_eca_value'
                                                className='form-control'
                                                name={field.name}
                                                onBlur={(e: any) => {
                                                    props.handleChange(e);
                                                    props.setFieldValue('totalseaPassage.fuel_type3_eca_value',
                                                        e.target.value * props?.values?.days)
                                                }}
                                                placeholder={window.screen.width <= 575 ? "Consumption in ECA" : ""}
                                            />
                                        )}
                                    </Field>
                                    <div className='input-group-text'>mt</div>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className='input-group mb-2'>
                                    <Field name='seaPassage.fuel_type3_non_eca_value'>
                                        {({ field, form }: FieldProps) => (
                                            <Input
                                                type="text"
                                                id='seaPassage.fuel_type3_non_eca_value'
                                                className='form-control'
                                                name={field.name}
                                                onBlur={(e: any) => {
                                                    props.handleChange(e);
                                                    props.setFieldValue('totalseaPassage.fuel_type3_non_eca_value',
                                                        e.target.value * props?.values?.days)
                                                }}
                                                placeholder={window.screen.width <= 575 ? "Consumption outside ECA" : ""}
                                            />
                                        )}
                                    </Field>
                                    <div className='input-group-text'>mt</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <h5>In Port operation</h5>
                        </Row>
                        <Row className='mb-3'>
                            <Col sm={4}>
                                <Label className='mb-0 asteric'>Name of port</Label>
                                <Field
                                    type="text"
                                    id='in_port_name'
                                    name='in_port_name'
                                    className='form-control mb-2'
                                />
                                {props.errors && props.errors.in_port_name &&
                                    <Label className='error'>{props.errors.in_port_name}</Label>
                                }
                            </Col>
                            <Col sm={1}>
                                <div className="form-check mt-3">
                                    <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="inPortLocation"
                                        id="eu"
                                        value="eu"
                                        defaultChecked
                                    />
                                    <Label className="form-check-label" htmlFor="eu">
                                        EU
                                    </Label>
                                </div>
                            </Col>
                            <Col sm={2}>
                                <div className="form-check mt-3">
                                    <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="inPortLocation"
                                        id="non_eu"
                                        value="non_eu"
                                    />
                                    <Label className="form-check-label" htmlFor="non_eu">
                                        NON EU
                                    </Label>
                                </div>
                            </Col>
                            <Col sm={{ size: 4, offset: 1 }}>
                                <Label className='mb-0 asteric'>Estimated days at port</Label>
                                <Field
                                    type="text"
                                    id='days_at_port'
                                    name='days_at_port'
                                    className='form-control mb-2'
                                    onChange={(e: any) => {
                                        props.handleChange(e);
                                    }}
                                    onBlur={(e: any) => {
                                        props.handleChange(e);
                                    }}
                                />
                                {props.errors && props.errors.days_at_port &&
                                    <Label className='error'>{props.errors.days_at_port}</Label>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <h6>Fuel consumption per day during port stay</h6>
                        </Row>
                        {window.screen.width > 575 &&
                            <Row>
                                <Col sm={4}>
                                    <Label>Type</Label>
                                </Col>
                                <Col sm={4}>
                                    <Label>Quantity</Label>
                                </Col>
                            </Row>
                        }
                        <Row className='mb-3'>
                            <Col sm={4}>
                                <Field
                                    name="portStay.fuel_type1">
                                    {({ field, form }: FieldProps) => (
                                        <AsyncSelect
                                            name={field.name}
                                            cacheOptions
                                            defaultOptions={true}
                                            loadOptions={loadFuelTypes}
                                            getOptionLabel={(e: any) => e?.fuel_type_name}
                                            getOptionValue={(e: any) => e?.id}
                                            onChange={(e: any) => {
                                                form.setFieldValue(field.name, e === null ? '' : e.id);
                                                form.setFieldValue('totalportStay.fuel_type1', e === null ? '' : e.id);
                                            }}
                                            menuPortalTarget={document.body}
                                            className='mb-2'
                                            placeholder={window.screen.width <= 575 ? "Type" : null}
                                        />
                                    )}
                                </Field>
                                {props.errors && props.errors.portStay &&
                                    <Label className='error'>{props.errors.portStay.fuel_type1}</Label>
                                }
                            </Col>
                            <Col sm={4}>
                                <div className='input-group mb-2'>
                                    <Field name='portStay.fuel_type1_value'>
                                        {({ field, form }: FieldProps) => (
                                            <Input
                                                type="text"
                                                id='portStay.fuel_type1_value'
                                                className='form-control'
                                                name={field.name}
                                                onBlur={(e: any) => {
                                                    props.handleChange(e);
                                                    props.setFieldValue('totalportStay.fuel_type1_value',
                                                        e.target.value * props?.values?.days)
                                                }}
                                                placeholder={window.screen.width <= 575 ? "Consumption" : ""}
                                            />
                                        )}
                                    </Field>
                                    <div className='input-group-text'>mt</div>
                                </div>
                                {props.errors && props.errors.portStay &&
                                    <Label className='error'>{props.errors.portStay.fuel_type1_value}</Label>
                                }
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col sm={4}>
                                <Field
                                    name="portStay.fuel_type2">
                                    {({ field, form }: FieldProps) => (
                                        <AsyncSelect
                                            name={field.name}
                                            cacheOptions
                                            defaultOptions={true}
                                            loadOptions={loadFuelTypes}
                                            getOptionLabel={(e: any) => e?.fuel_type_name}
                                            getOptionValue={(e: any) => e?.id}
                                            onChange={(e: any) => {
                                                form.setFieldValue(field.name, e === null ? '' : e.id);
                                                form.setFieldValue('totalportStay.fuel_type1', e === null ? '' : e.id);
                                            }}
                                            isClearable={true}
                                            menuPortalTarget={document.body}
                                            className='mb-2'
                                            placeholder={window.screen.width <= 575 ? "Type" : null}
                                        />
                                    )}
                                </Field>
                            </Col>
                            <Col sm={4}>
                                <div className='input-group mb-2'>
                                    <Field name='portStay.fuel_type2_value'>
                                        {({ field, form }: FieldProps) => (
                                            <Input
                                                type="text"
                                                id='portStay.fuel_type2_value'
                                                className='form-control'
                                                name={field.name}
                                                onBlur={(e: any) => {
                                                    props.handleChange(e);
                                                    props.setFieldValue('totalportStay.fuel_type2_value',
                                                        e.target.value * props?.values?.days)
                                                }}
                                                placeholder={window.screen.width <= 575 ? "Consumption" : ""}
                                            />
                                        )}
                                    </Field>
                                    <div className='input-group-text'>mt</div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col sm={4}>
                                <Field
                                    name="portStay.fuel_type3">
                                    {({ field, form }: FieldProps) => (
                                        <AsyncSelect
                                            name={field.name}
                                            cacheOptions
                                            defaultOptions={true}
                                            loadOptions={loadFuelTypes}
                                            getOptionLabel={(e: any) => e?.fuel_type_name}
                                            getOptionValue={(e: any) => e?.id}
                                            onChange={(e: any) => {
                                                form.setFieldValue(field.name, e === null ? '' : e.id);
                                                form.setFieldValue('totalportStay.fuel_type1', e === null ? '' : e.id);
                                            }}
                                            isClearable={true}
                                            menuPortalTarget={document.body}
                                            className='mb-2'
                                            placeholder={window.screen.width <= 575 ? "Type" : null}
                                        />
                                    )}

                                </Field>
                            </Col>
                            <Col sm={4}>
                                <Field name='portStay.fuel_type3_value'>
                                    {({ field, form }: FieldProps) => (
                                        <Input
                                            type="text"
                                            id='portStay.fuel_type3_value'
                                            className='form-control mb-2'
                                            name={field.name}
                                            onBlur={(e: any) => {
                                                props.handleChange(e);
                                                props.setFieldValue('totalportStay.fuel_type3_value',
                                                    e.target.value * props?.values?.days)
                                            }}
                                            placeholder={window.screen.width <= 575 ? "Consumption" : ""}
                                        />
                                    )}
                                </Field>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pos-center'>
                                <div className='ele_row'>
                                    <Button className='btn btn-primary mt-5' type='submit'>Calculate</Button>
                                    <Button className='btn btn-primary mt-5 ms-5' type='reset' onClick={() => props.resetForm({
                                        values: VoyageCalculatiorFormik.initailValues
                                    })}>Reset</Button>
                                </div>
                            </Col>
                        </Row>
                        {modalState === true &&
                            <CIICalculatorModal
                                state={modalState}
                                setState={setModalState}
                                id={recordId}
                                calculator='voyage'
                                calculateSeaCO2={calculateSeaCO2(props.values)}
                                calculatePortCO2={calculatePortCO2(props.values)}
                                calculateTotalEUA={calculateTotalEUA(props.values)}
                            />
                        }
                    </Form>
                )}
            </Formik>
        </CardBody >
    )
}

export default VoyageCalculator